body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Comic Sans MS';
  src: local('Comic Sans MS'),
    url('fonts/ComicSansMS.ttf');
  /* font-weight: bold; */
}

@font-face {
  font-family: 'Krivulya Regular';
  src: local('Krivulya Regular'),
    url('fonts/KrivulyaRegular.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'InterTight Light';
  src: local('InterTightLight'),
    url('fonts/InterTightLight.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'InterTight Medium';
  src: local('InterTightMedium'),
    url('fonts/InterTightMedium.ttf');
  font-weight: bold;
}


@font-face {
  font-family: 'NotoSerifTC Bold';
  src: local('NotoSerifTCBold'),
    url('fonts/NotoSerifTCBold.otf');
  font-weight: bold;
}
